//src/api/common.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 购买订单列表：
export function getBooksObj(obj) {
    return request({
        url: '/payment/pays/queryOrderList',   // url = base url + request url
        method: 'get',
        params: obj,
    })
}






// 充值订单列表：
export function queryOrderListObj(obj) {
    return request({
        url: '/payment/pays/queryOrderList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 充值订单详情：
export function OrderIndorObj(obj) {
    return request({
        url: `/payment/pays/getOrder/${obj.orderNo}`,    // url = base url + request url
        method: 'get',
    })
}
// 查询课时购买列表：
export function queryBuyListObj(obj) {
    return request({
        url: '/study/transactions/queryBuyList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}
// 查询整本购买列表：
export function WholeByListObj(obj) {
    return request({
        url: '/study/transactions/queryWholeByList',   // url = base url + request url
        method: 'post',
        data: obj,
    })
}


