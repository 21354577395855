<template>
  <div class="case-conent order">
    <div class="hd">订单</div>
    <!--搜索结果-->
    <div class="table-content-search">
      <div class="tab-tilte">
        <li @click="orderObjA()" :class="{active:orderCur==0}">充值订单</li>
        <li @click="orderObjB()" :class="{active:orderCur==1}">课时购买记录</li>
        <li @click="orderObjC()" :class="{active:orderCur==2}">整本书购买记录</li>
      </div>
      <div class="tab-content">
        <div class="tableBox" v-show="orderCur==0">
          <el-table
                  border
                  :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                  :data="queryOrderListData"
          >
            <el-table-column prop="sort" label="序号"  header-align="center" width="60"></el-table-column>
            <el-table-column prop="orderNo" label="订单号"  header-align="center"></el-table-column>
            <el-table-column prop="stuName" label="学生姓名" header-align="center"></el-table-column>
            <el-table-column prop="payment" label="充值金额" header-align="center" width="100"></el-table-column>
            <el-table-column prop="payType" label="充值方式" header-align="center" width="100">
              <template #default="scope">
                <span class="textlog1"  v-if="scope.row.payType==1">支付宝</span>
                <span class="textlog1"  v-if="scope.row.payType==2">微信</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" header-align="center" width="100">
              <template #default="scope">
                <span class="textlog1"  v-if="scope.row.status==0">未支付</span>
                <span class="textlog1"  v-if="scope.row.status==1">支付完成</span>
                <span class="textlog1"  v-if="scope.row.status==2">支付异常</span>
              </template>
            </el-table-column>
            <el-table-column prop="payTime" label="充值时间" header-align="center" width="200"></el-table-column>

            <el-table-column prop="" label="操作" header-align="center" width="120">
              <template #default="scope">
                <el-button @click="rechargeObj(scope)" type="primary" size="mini" plain>详情</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <div class="tableBox" v-show="orderCur==1">
          <el-table
                  border
                  :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                  :data="BuyListData"
          >
            <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
            <el-table-column prop="subjectName" label="学科"  header-align="center" width="80">

            </el-table-column>
            <el-table-column prop="bookName" label="图书名称" header-align="center" ></el-table-column>
            <el-table-column prop="resourceName" label="课时名称" header-align="center"></el-table-column>
            <el-table-column prop="tradeAmout" label="购买金额" header-align="center" width="180"></el-table-column>
            <el-table-column prop="tradeTime" label="购买时间" header-align="center" width="200"></el-table-column>
            <el-table-column prop="" label="操作" header-align="center" width="150">
              <template #default="scope">
                <el-button @click="tosDetails(scope)" type="primary" size="mini" plain>看学历案</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tableBox" v-show="orderCur==2">
          <el-table
                  border
                  :header-cell-style="{background:'#D1EEFE',color:'#333'}"
                  :data="WholeByListData"
          >
            <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
            <el-table-column prop="subjectName" label="学科"  header-align="center" width="80">
              <template #default="scope">
                <span class="colourBox">{{scope.row.subjectName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="bookName" label="图书名称" header-align="center" ></el-table-column>
            <!--              <el-table-column prop="" label="购买类型" header-align="center" width="80">-->
            <!--                <span class="textlog1">整书购买</span>-->
            <!--              </el-table-column>-->
            <el-table-column prop="tradeAmout" label="购买金额" header-align="center" width="150"></el-table-column>
            <el-table-column prop="tradeTime" label="购买时间" header-align="center" width="200"></el-table-column>
            <el-table-column prop="" label="操作" header-align="center" width="180">
              <template #default="scope">
                <el-button @click="toCatalogue(scope)" type="primary" size="mini" plain>看目录</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="page" >
        <div class="pag-box" v-if="queryOrderListData !=0&&orderCur==0">
          <div class="total-box">
            <span class="total_pag">共<em> {{ totalA }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPageA"
              background
              :page-size="pageSizeA"
              layout="prev, pager, next"
              :total="totalA"
              @size-change="handleSizeChangeA"
              @current-change="handleCurrentChangeA"
              v-if="totalA != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
        <div class="pag-box" v-if="BuyListData !=''&&orderCur==1">
          <div class="total-box">
            <span class="total_pag">共<em> {{ totalB }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPageB"
              background
              :page-size="pageSizeB"
              layout="prev, pager, next"
              :total="totalB"
              @size-change="handleSizeChangeB"
              @current-change="handleCurrentChangeB"
              v-if="totalB != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
        <div class="pag-box" v-if="WholeByListData !=''&&orderCur==2">
          <div class="total-box">
            <span class="total_pag">共<em> {{ totalC }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPageC"
              background
              :page-size="pageSizeC"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChangeC"
              @current-change="handleCurrentChangeC"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 充值订单详情弹框-->
    <el-dialog :visible.sync="rechargeDialog" :modal-append-to-body='false'  title="充值订单详情" width="800px" left>
      <div class="dialogOrder">
        <div class="dialogBody">
          <div class="lf">商品名称：</div>
          <div class="rg">学历案充值</div>
        </div>
        <div class="dialogBody">
          <div class="lf">订单号：</div>
          <div class="rg">{{OrderIndorDataA.order_no}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">学生姓名：</div>
          <div class="rg">{{OrderIndorDataA.stu_name}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值金额：</div>
          <div class="rg">{{OrderIndorDataA.payment}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值方式：</div>
          <div class="rg">
            <span v-if="OrderIndorDataA.pay_type==1">支付宝</span>
            <span v-if="OrderIndorDataA.pay_type==2">微信</span>
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">状态：</div>
          <div class="rg">
            <span v-if="OrderIndorDataA.status==0">未支付</span>
            <span v-if="OrderIndorDataA.status==1">支付完成</span>
            <span v-if="OrderIndorDataA.status==2">支付异常</span>
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值时间：</div>
          <div class="rg">
            {{ OrderIndorDataA.pay_time }}
          </div>
        </div>

      </div>
      <div class="dialogFooter">
        <el-button @click="closObj()" round style="border: 1px solid #1A86FF; background-color: #1A86FF; color: #fff" type="primary">确定</el-button>
      </div>
    </el-dialog>

  </div>

</template>


<script>
import moment from 'moment';
import {queryOrderListObj,OrderIndorObj,queryBuyListObj,WholeByListObj} from '@/api/order'   //路径
export default {
  name: '',
  data() {
    return {
      userInfo:{

      },
      OrderIndorDataA:{},
      pageSizeA:10,
      pageCurrentA:null,
      totalA:null,
      pageSizeB:10,
      pageCurrentB:null,
      totalB:null,
      pageSizeC:10,
      pageCurrentC:null,
      totalC:null,
      queryOrderListData:[],//充值订单列表
      BuyListData:[],//课时购买订单列表
      WholeByListData:[],//整本购买列表
      d1:[],
      d2:[],
      totalMoney:0,//订单总金额
      orderCur:false,
      OrderIndorData:[],
      listData:[
        {
          sort:1,
          orderNumber:'GR202109300001',
          stuName:'张三',
          rechargeType:'按需充值',
          originalPrice:2356,
          rechargeAmount:1000,
          discount:7,
          rechargeMode:'支付宝',
          state:1,
          time:'2021-10-12 15:38:45'
        }
      ],
      rechargeDialog:false,
      buylistData:[
        {
          sort:1,
          subject:'语文',
          bookName:'GR202109300001',
          chapter:'第一单元 一 《合欢树》',
          buyType:1,
          purchaseAmount:2356,
          purchaseMethod:1,
          state:1,
          time:'2021-10-12 15:38:45'
        }
      ],
    }
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.currentPageA= this.getContextData("OrderACurrentPage") || 1;
    this.currentPageB= this.getContextData("OrderBCurrentPage") || 1;
    this.currentPageC= this.getContextData("OrderCCurrentPage") || 1;
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag=this.$route.query.clickFlag;
    this.orderCur=this.$route.query.orderCur;

    this.queryOrListObj()
    this.queryBuyList()
    this.WholeByList()
  },
  methods: {
    //给sessionStorage存值
    setContextData: function(key, value) {
      if(typeof value == "string"){
        sessionStorage.setItem(key, value);
      }else{
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    },
    // 从sessionStorage取值
    getContextData: function(key){
      const str = sessionStorage.getItem(key);
      if( typeof str == "string" ){
        try{
          return JSON.parse(str);
        }catch(e) {
          return str;
        }
      }
      return;
    },
    orderObjA() {
      this.orderCur=0
      this.queryOrListObj()
    },
    orderObjB() {
      this.orderCur=1
      this.queryBuyList()
    },
    orderObjC() {
      this.orderCur=2
      this.WholeByList()
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    //跳转到目录
    toCatalogue(scope) {
      this.$router.push({
        path: "/mainb/catalogue",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: this.clickFlag,
          //bookId: 55,
          bookId: scope.row.bookId,
          stu:1
          //totalPrice: scope.totalPrice,
         // totalDiscount: scope.totalDiscount,
        }
      });
    },
    // 去详情
    tosDetails(scope) {
      this.$router.push({
        path: "/mainb/details",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: this.clickFlag,
          bookId: scope.row.bookId,
          folderId: scope.row.folderId,
          questionType: 1,
          id: scope.row.resId,
          type:1,
          stu:1,
          orderCur:1
        }
      });
    },

    //充值详情弹框
    rechargeObj(scope) {
      this.rechargeDialog=true
      let obj = {
        orderNo: scope.row.orderNo,
      }
      OrderIndorObj(obj).then((res) => { // eslint-disable-line no-unused-vars
        // this.picList = res.data.imgs
        this.OrderIndorDataA = res.data
      })
    },
    closObj() {
      this.rechargeDialog=false
    },

    //购买详情弹框
    buyObj(scope) {
      this.d2= scope.row
      this.buyDialog=true
    },

    //充值订单列表
    queryOrListObj() {
      let params = {
        pageCurrent: this.currentPageA,//初始页
        pageSize: this.pageSizeA,//每页的数据
      }
      queryOrderListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.queryOrderListData = res.data.data.records
        this.pageSizeA = res.data.data.size;
        this.currentPageA = res.data.data.current;
        this.totalA = res.data.data.total
      })
    },
    //充值订单列表分页
    handleSizeChangeA(val) {
      this.pageSizeA = val;
      this.queryOrListObj();
    },
    handleCurrentChangeA(val) {
      this.currentPageA = val;
      this.setContextData("OrderACurrentPage", this.currentPageA);
      // 刷新页面内容
      this.queryOrListObj();
    },
    //购买订单列表
    queryBuyList() {
      let params = {
        pageCurrent: this.currentPageB,//初始页
        pageSize: this.pageSizeB,//每页的数据
      }
      queryBuyListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.BuyListData = res.data.data.records
        this.pageSizeB = res.data.data.size;
        this.currentPageB = res.data.data.current;
        this.totalB = res.data.data.total
      })
    },
    handleSizeChangeB(val) {
      this.pageSizeB = val;
      this.queryBuyList();
    },
    handleCurrentChangeB(val) {
      this.currentPageB = val;
      this.setContextData("OrderBCurrentPage", this.currentPageB);
      // 刷新页面内容
      this.queryBuyList();
    },
    //整本购买列表
    WholeByList() {
      let params = {
        pageCurrent: this.currentPageC,//初始页
        pageSize: this.pageSizeC,//每页的数据
      }
      WholeByListObj(params).then((res) => { // eslint-disable-line no-unused-vars
        this.WholeByListData = res.data.data.records
        this.pageSizeC = res.data.data.size;
        this.currentPageC = res.data.data.current;
        this.totalC = res.data.data.total
      })
    },
    handleSizeChangeC(val) {
      this.pageSizeC = val;
      this.WholeByList();
    },
    handleCurrentChangeC(val) {
      this.currentPageC = val;
      this.setContextData("OrderCCurrentPage", this.currentPageC);
      // 刷新页面内容
      this.WholeByList();
    },
    //添加到购物车
    addCart() {
      // let params = {
      //   productId:this.id,
      //   selected:true,
      // }
      // carts(params).then((res) => { // eslint-disable-line no-unused-vars
      //   this.showModal = true;
      //   this.$store.dispatch('saveCartCount',res.data.cartTotal)
      // })
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }
  .table-content-search {
    height: 96%;
    .tab-tilte{
      width: 96%;
      height: 36px;
      border-bottom: 1px solid #FF9100;
      margin: 10px auto;
      li{
        float: left;
        width: 25%;
        height: 35px;
        line-height: 35px;
        padding: 0px 0;
        text-align: center;
        margin-right: 10px;
        font-weight: bold;
        background-color:#FFF5E8;
        border: 1px solid #FF9100;
        cursor: pointer;
        border-radius: 6px 6px 0px 0px;
      }
      .active{
        background-color: #FF9100;
        color: #fff;
      }
    }
    .tab-content {
      width: 96%;
      margin: 10px auto;
      .tableBox {
        box-sizing: border-box;


      }
    }
  }


  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
